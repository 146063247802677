.background-image {
    position: fixed;
    height: 100vh;
    z-index:-1000;
    img {
        position: fixed;
        right:0;
        height:100vh;
    }
}

#landing li {
    margin-bottom: 1rem;
}

.full-width {
    width:100%;
}