@import './_variables.scss';

#cv {
    background: $dark;
    box-shadow: 0px 0px 3px rgba($dark, .5);
    section {
        padding:14px 14px 14px 56px;
        h2 {
            border-top: 3px groove $gray-300; 
        }
    }

    #cv-side-nav {
        position:relative;
    }

    #cv-side-nav-content {
        position: fixed;
    }

    .navLink {
        display:inline-block;
        padding: 8px;
        width:100%;
        &.active {
            background: $primary;
            border-radius: 4px;
            color: $white;
            font-weight: bold;
        }
    }

    .progress {
        background: $white;
        .bg-primary {
            background: darken($primary, 20);
        }
    }
}